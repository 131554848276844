import { useJsApiLoader } from "@react-google-maps/api";
import { GoogleGeocodeLocation, libraries } from "utils/googleMapsUtils";
import { NextPage } from "next";
import Link from "next/link";
import { useEffect, useRef, useState } from "react";
import LocationModal from "@/components/location/LocationModal";
import Footer from "@/components/mainComponents/Footer";
import Navbar from "@/components/mainComponents/Navbar";
import { useRouter } from "next/router";

interface ErrorProps {
  statusCode: number;
}

const Error: NextPage<ErrorProps> = ({ statusCode }) => {
  const [zipcode, setZipcode] = useState("");
  const [location, setLocation] = useState("");
  const [geocodeLocation, setGeocodeLocation] =
    useState<GoogleGeocodeLocation>(null);
  const apiKey = process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY;
  const [open, setOpen] = useState(false);
  const router = useRouter();
  const [searchQueryError, setSearchQueryError] = useState("");
  const [zipcodeError, setZipcodeError] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const handleSubmit = (e, curZipcode = zipcode) => {
    e.preventDefault();
    if (searchQuery.length < 3) {
      setSearchQueryError(
        "Your search query must be at least 3 characters long.",
      );
    } else {
      setSearchQueryError("");
    }

    if (curZipcode.length !== 5) {
      setZipcodeError("Your zip code must be exactly 5 characters long.");
    } else {
      setZipcodeError("");
    }

    if (searchQuery.length < 3 || curZipcode.length !== 5) {
      // Display an error message or perform any desired action
      console.log("ERROR");
      return;
    }
    router.push(`/loading?searchQuery=${searchQuery}&zipcode=${curZipcode}`);
  };

  const handleLocationInputClick = () => {
    setOpen(true);
  };

  const openRef = useRef(open);

  useEffect(() => {
    openRef.current = open;
  }, [open]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: apiKey,
    libraries: libraries,
  });

  const props = {
    navbar: {
      handleLocationInputClick,
      zipcode,
      setZipcode,
      handleSubmit,
      setSearchQuery,
      googleLoaded: isLoaded,
    },
    locationModal: {
      open,
      setOpen,
      openRef,
      zipcode,
      setZipcode,
      isLoaded,
      apiKey,
      setLocation,
      geocodeLocation,
      setGeocodeLocation,
      handleSubmit,
    },
  };

  return (
    <>
      <Navbar {...props.navbar} />
      {open ? <LocationModal {...props.locationModal} /> : null}

      <div className="flex h-screen flex-col">
        <header
          className="center h-full justify-items-center bg-youdleLightGray py-12"
          id="header"
        >
          <div className="flex items-center justify-center">
            <div className="text-[10rem] text-red sm:text-[5rem] lg:text-[18rem]">
              {statusCode}
            </div>
          </div>
          <div className="flex items-center justify-center">
            <Link
              className="h-12 w-full rounded-3xl bg-red py-3.5 text-center font-semibold text-white md:w-max md:px-6"
              type="button"
              href="/"
            >
              Back to Home
            </Link>
          </div>
        </header>
        <Footer />
      </div>
    </>
  );
};

Error.getInitialProps = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

export default Error;
